<template>
  <main-layout>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card class="elevation-0 transparent">
            <v-list two-line class="transparent pt-0">
              <v-list-item>
                <v-list-item-action>
                  <v-icon class="blue--text text--lighten-2">mdi-phone</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>02/767 40 18</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon class="blue--text text--lighten-2">mdi-map-marker-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>Hertenbergstraat 40 3080 Tervuren
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
          <gmap-map
            :center="{lat: 50.821782, lng: 4.511762}"
            :zoom="14"
            style="width: 100%; height: 300px"
          >
            <gmap-marker
              key="index"
              :position="{lat: 50.816782, lng: 4.511762}"
              :clickable="true"
              :draggable="true"
              @click="center=m.position"
            ></gmap-marker>
          </gmap-map>
        </v-flex>
      </v-layout>
    </v-container>
  </main-layout>
</template>

<script>
  import MainLayout from '@/layouts/Main.vue'

  export default {
    components: {
      MainLayout
    },
    metaInfo () {
      return {title: this.$t('navigation.contact')}
    }
  }
</script>
